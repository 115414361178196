import React, { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import NavBar from '../../../components/NavBar/NavBar'
import {
  Invisible,
  MainContainer,
  HeadingContainer,
  Heading,
  Text,
  SmallText,
  MobileHeading,
  RegularHeading,
  BackgroundGradient,
} from './styles'

import {
  hasJoinedCampaign,
  CampaignLocale,
  CAMPAIGN_IDS,
  useVLCampaign,
  useAutoRedirectToWaitList,
} from '../../../utils/viralLoops'

import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const Main = ({ location }: { location: any }) => {
  const { t } = useTranslation()

  let campaignLocale: CampaignLocale =
    location.pathname === '/br/' ? 'BR' : 'EN'

  const campaign = useVLCampaign(campaignLocale)

  useAutoRedirectToWaitList(campaign)

  return (
    <MainContainer>
      <StaticImage
        className="main"
        src="../../../images/Main.png"
        alt="hero"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
      <BackgroundGradient />
      <StaticImage
        className="smallMain"
        src="../../../images/mainSmall.png"
        alt="hero"
        placeholder="blurred"
        layout="fullWidth"
        objectFit="cover"
      />
      <NavBar />
      <HeadingContainer>
        <RegularHeading>
          <Heading className="bottomHeading">
            <Trans>hero1</Trans>
          </Heading>
        </RegularHeading>

        <MobileHeading>
          <Heading className="bottomHeading">{t('hero1')}</Heading>
        </MobileHeading>
        <Text className="lastText">{t('mainText1')}</Text>
        <SmallText>{t('mainText1')}</SmallText>

        <button
          className="btn learn-more"
          onClick={() => {
            window.location.href = 'https://6cid0.app.link/download'
          }}
        >
          {t('downloadNow')}
        </button>
        <Invisible>
          <form-widget
            mode="popup"
            ucid={CAMPAIGN_IDS[campaignLocale]}
            className="form"
          ></form-widget>
        </Invisible>
      </HeadingContainer>
    </MainContainer>
  )
}

export default Main
