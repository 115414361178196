import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'

import buzzLogo from '../../images/buzzLogo.svg'
import {
  BuzzLogo,
  HiringBtn,
  LogoContainer,
  MainContainer,
  LinksContainer,
} from './styles'
import LanguageSelector from '../LanguageSelecter/LanguageSelector'

const NavBar = () => {
  const { t } = useTranslation()

  return (
    <MainContainer id="Nav">
      <LogoContainer>
        <BuzzLogo src={buzzLogo} />
        <HiringBtn
          className="hire"
          target="_blank"
          rel="noreferrer noopener"
          href="https://apply.workable.com/buzz-3/"
        >
          {t('hiring')}
        </HiringBtn>
      </LogoContainer>
      <LinksContainer>
        <Link to="Earn" smooth={true} className="link">
          {t('freedomCaps')}
        </Link>
        <Link to="Invest" smooth={true} className="link">
          {t('investCaps')}
        </Link>
        <Link to="Social" smooth={true} className="link">
          SOCIAL
        </Link>
      </LinksContainer>
      <LanguageSelector color="yellow" />
    </MainContainer>
  )
}

export default NavBar
