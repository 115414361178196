import styled from 'styled-components'

export const MainContainer = styled.div`
  max-width: 1700px;
  margin: 0 auto;
  width: 95%;
  display: flex;
  justify-content: space-between;
  padding-top: 4em;
  position: relative;

  @media only screen and (min-device-width: 375px) and (max-device-width: 860px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    padding: 0 3em;
    padding-top: 4em;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0 1em;
    padding-top: 2em;
  }
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 4em;
  width: 50%;

  @media only screen and (max-width: 600px) {
    width: auto;
    gap: 1em;
  }
`

export const LinksContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 9em;
  width: 50%;

  @media only screen and (max-width: 1130px) {
    gap: 4em;
  }

  @media only screen and (max-width: 960px) {
    display: none;
  }

  .link {
    color: #ffff3e;
    display: inline-block;
    font-size: 2em;
    font-weight: 700;
    letter-spacing: 0.05em;
    transition: all 0.25s linear;
    cursor: pointer;

    &:hover {
      transform: translateY(-4px);
    }

    &:active {
      transform: translateY(1px);
      color: #fff;
    }
  }
`

export const HiringBtn = styled.a`
  width: auto;
  font-weight: 800;
  color: #000;
  text-transform: uppercase;
  background: #ffff3e;
  border-radius: 4em;
  padding: 1em 2em;
  position: relative;
  display: block;
  cursor: pointer;
  outline: none;
  border: 0;
  text-decoration: none;
  font-size: 1.6em;
  font-weight: 800;
  white-space: pre;

  &:hover {
    background: #eded3a;
  }

  &:active {
    background: #dbdb35;
  }

  @media screen and (orientation: landscape) and (max-device-width: 900px) {
    font-size: 1.2em;
  }

  @media only screen and (max-width: 600px) {
    text-align: center;
    padding: 1.4em;
    font-size: 1.1em;
    margin-right: 2.6em;
  }
`

export const BuzzLogo = styled.img`
  @media screen and (orientation: landscape) and (max-device-width: 900px) {
    width: 30%;
  }
  @media only screen and (max-width: 600px) {
    width: 38%;
  }
`
