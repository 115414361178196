import { graphql, navigate } from 'gatsby'
import React, { useEffect } from 'react'

import Cards from '../fragments/index/Cards/Cards'
import Main from '../fragments/index/Main/Main'
import MakeMoney from '../fragments/index/MakeMoney/MakeMoney'
import Phones from '../fragments/index/Phones/Phones'
import Freedom from '../fragments/index/Freedom/Freedom'
import Social from '../fragments/index/Social/Social'
import Investor from '../fragments/index/Investor/Investor'
import Footer from '../components/Footer/Footer'
import Layout from '../components/Layout/Layout'

const Index = ({ location }) => {
  return (
    <>
      <Main location={location} />
      <Phones />
      <MakeMoney />
      <Cards />
      <Freedom />
      <Investor />
      <Social />
      <Footer />
    </>
  )
}

const LayoutContainer = props => {
  return (
    <>
      <Layout>
        <Index {...props} />
      </Layout>
    </>
  )
}

export default LayoutContainer

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
