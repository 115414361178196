import styled from 'styled-components'

export const BackgroundGradient = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(60, 60, 60, 0.202), rgba(60, 60, 60, 0.208));
  z-index: -1;
`

export const MainContainer = styled.div`
  z-index: -1;
  width: 100%;

  .btn {
    width: auto;
    color: #000;
    text-transform: uppercase;
    background: #ffff3e;
    border-radius: 4em;
    padding: 1em 2em;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    font-size: 1.7em;
    font-weight: 800;

    &:hover {
      background: #eded3a;
    }

    &:active {
      background: #dbdb35;
    }

    @media only screen and (min-device-width: 375px) and (max-device-width: 860px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
      font-size: 1.2em;
      padding: 1em 2em;
      margin-top: 2em;
    }

    @media only screen and (max-width: 600px) {
      width: 100%;
      padding: 1em;
      height: 5em;
      font-size: 1.2em;
      margin-top: 4em;
    }
  }

  .main {
    z-index: -1;
    position: absolute;
    width: 100vw;
    height: 100vh;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .smallMain {
    display: none;
    @media only screen and (max-width: 600px) {
      display: block;
      position: absolute;
      width: 100vw;
      height: 90em;
      z-index: -1;
      opacity: 0.93;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`

export const HeadingContainer = styled.div`
  width: 100vw;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .lastText {
    margin-bottom: 2em;
  }

  .gatsby-link {
    color: #000;
  }

  @media only screen and (min-device-width: 375px) and (max-device-width: 860px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    justify-content: flex-start;
    margin-top: 4.8em;

    .lastText {
      margin-bottom: 0.4em;
    }
  }

  .bottomHeading {
    margin-bottom: 2rem;
    margin-top: 0;

    @media only screen and (max-width: 600px) {
      margin-bottom: 3.2rem;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0 3rem;
    margin-top: 4rem;
  }
`

export const RegularHeading = styled.div`
  @media only screen and (max-width: 600px) {
    display: none;
  }
`

export const MobileHeading = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    display: block;
    margin-top: 10em;
  }
`
// Hack to have our own custom trigger and hide the original. (display none doesn't work, hides modal)
export const Invisible = styled.div`
  height: 0px;
  overflow: hidden;
`

export const Heading = styled.div`
  font-family: 'Archivo ExtraBold';
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 152px;
  color: #ffff3e;
  letter-spacing: 0.009em;
  line-height: 128px;
  margin-top: 0.4em;
  text-align: center;

  @media only screen and (max-width: 1740px) {
    font-size: 152px;
  }

  @media only screen and (max-width: 1700px) {
    font-size: 148px;
    line-height: 88%;
  }

  @media only screen and (max-width: 1500px) {
    font-size: 12em;
  }

  @media only screen and (max-width: 1300px) {
    font-size: 10em;
    line-height: 100%;
  }

  //IPAD
  @media only screen and (max-width: 1100px) {
    font-size: 8em;
  }

  @media only screen and (max-width: 830px) {
    font-size: 6em;
    text-align: center;
  }

  //Mobile Landscape
  @media only screen and (min-device-width: 375px) and (max-device-width: 860px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    font-size: 4em;
  }

  @media only screen and (max-width: 600px) {
    font-size: 6em;
    line-height: 91%;
    margin-top: 0;
  }
`

export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4em;
  color: #ffff3e;
  font-weight: 500;
  letter-spacing: 0.05em;
  line-height: 117%;
  text-shadow: 1px 1px 0.5px #000, 0 0 0.01em #ffff3e, 0 0 0.01em #ffff3e;

  @media only screen and (min-device-width: 375px) and (max-device-width: 860px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
    font-size: 1.8em;
  }

  @media only screen and (max-width: 600px) {
    display: none;
    backdrop-filter: blur(0.02px);
  }
`

export const SmallText = styled.div`
  display: none;
  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
    color: #ffff3e;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 117%;
    text-shadow: 1px 1px 0.5px #000, 0 0 0.01em #ffff3e, 0 0 0.01em #ffff;
    text-align: center;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2.2em;
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`
